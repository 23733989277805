/*
 * @Author: your name
 * @Date: 2021-11-15 15:25:58
 * @LastEditTime: 2021-11-16 13:46:35
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \crm-product（西凤产品web）\found\views\sfa\views\reports\travel_promotion\index.js
 */
/*
 * 实物促销
 */
import TablePage from '@/found/components/table_page';
import Form from './form/index.js';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
    };
  },
  components: {
    Form,
  },
  methods: {
    modalClick({
      val,
      row,
    }) {
      const {
        code,
      } = val;
      if (code === 'view') {
        this.modalConfig.title = '详情';
        this.formName = 'Form';

        this.formConfig = {
          name: 'LYCX',
          code,
          row,
        };
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('travel_promotion_list');
  },

};
